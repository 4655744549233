<template>
  <div>

    <vs-table :data="lunchDonorData" v-if="lunchDonorData" striped
              :pagination="false" :max-items="50">
      <template slot="header">
        <div class="d-inline-block float-left">
          <h2>Koriščenje donacij kosil <vs-chip style="float: right;" class="mt-1 ml-3">{{ lunchDonorData.length }}</vs-chip></h2>
        </div>
      </template>

      <template slot="thead" v-if="lunchDonorData.length > 0">
        <vs-th sort-key="id">
          <!-- empty -->
        </vs-th>
        <vs-th>
          Donator
        </vs-th>
        <vs-th sort-key="first_name">
          Število koriščenih donacij v mesecu
        </vs-th>
        <vs-th sort-key="first_name">
          Datum koriščenja
        </vs-th>
        <vs-th v-if="actionsVisible">
          Akcije
        </vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="'active_' + index" v-for="(tr, index) in data" :data="tr">

          <vs-td :data="data[index]" style="width: 70px;">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              {{ index + 1 }}
            </vs-col>
          </vs-td>

          <vs-td :data="data[index].donor">
            <h4>{{data[index].donorData ? data[index].donorData.companyDisplayName : 'brez'}}</h4>
          </vs-td>

          <vs-td :data="data[index].data" style="width: 300px;">
            {{data[index].data ? data[index].data.length : 0}}
          </vs-td>

          <vs-td :data="data[index].data" style="width: 200px;">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              <ul>
                <li v-for="(item,idx) in data[index].data" :key="'item_' + idx + '_' + moment(item.date).valueOf()">
                  {{moment(item.date).format('DD. MM. YYYY')}}
                </li>
              </ul>
            </vs-col>
          </vs-td>

          <vs-td :data="data[index]" @mousedown.stop.prevent="stopTheEvent()" style="width: 200px;" v-if="actionsVisible">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              <vs-dropdown>
                <vs-button class="btn-drop" type="filled" icon="expand_more">Akcije</vs-button>

                <vs-dropdown-menu>
                  <vs-dropdown-item @click="printReport(data[index], 'lunchDonor')"> Natisni poročilo
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-col>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <vs-table :data="breakfastDonorData" class="mt-5" v-if="breakfastDonorData" striped
              :pagination="false" :max-items="50">
      <template slot="header">
        <div class="d-inline-block float-left">
          <h2>Koriščenje donacij zajtrkov <vs-chip style="float: right;" class="mt-1 ml-3">{{ breakfastDonorData.length }}</vs-chip></h2>
        </div>
      </template>

      <template slot="thead" v-if="breakfastDonorData.length > 0">
        <vs-th sort-key="id">
          <!-- empty -->
        </vs-th>
        <vs-th>
          Donator
        </vs-th>
        <vs-th sort-key="first_name">
          Število koriščenih donacij v mesecu
        </vs-th>
        <vs-th sort-key="first_name">
          Datum koriščenja
        </vs-th>
        <vs-th v-if="actionsVisible">
          Akcije
        </vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="'active_' + index" v-for="(tr, index) in data" :data="tr">

          <vs-td :data="data[index]" style="width: 70px;">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              {{ index + 1 }}
            </vs-col>
          </vs-td>

          <vs-td :data="data[index].donor">
            {{data[index].donorData ? data[index].donorData.companyDisplayName : 'brez'}}
          </vs-td>

          <vs-td :data="data[index].data" style="width: 300px;">
            {{data[index].data ? data[index].data.length : 0}}
          </vs-td>

          <vs-td :data="data[index].data" style="width: 200px;">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              <ul>
                <li v-for="(item,idx) in data[index].data" :key="'item_' + idx + '_' + moment(item.date).valueOf()">
                  {{moment(item.date).format('DD. MM. YYYY')}}
                </li>
              </ul>
            </vs-col>
          </vs-td>

          <vs-td :data="data[index]" @mousedown.stop.prevent="stopTheEvent()" style="width: 200px;" v-if="actionsVisible">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              <vs-dropdown>
                <vs-button class="btn-drop" type="filled" icon="expand_more">Akcije</vs-button>

                <vs-dropdown-menu>
                  <vs-dropdown-item @click="printReport(data[index], 'breakfastDonor')"> Natisni poročilo
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-col>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <vs-table :data="drinkDonorData" class="mt-5" v-if="drinkDonorData" striped
              :pagination="false" :max-items="50">
      <template slot="header">
        <div class="d-inline-block float-left">
          <h2>Koriščenje donacij toplih napitkov <vs-chip style="float: right;" class="mt-1 ml-3">{{ drinkDonorData.length }}</vs-chip></h2>
        </div>
      </template>

      <template slot="thead" v-if="drinkDonorData.length > 0">
        <vs-th sort-key="id">
          <!-- empty -->
        </vs-th>
        <vs-th>
          Donator
        </vs-th>
        <vs-th sort-key="first_name">
          Število koriščenih donacij v mesecu
        </vs-th>
        <vs-th sort-key="first_name">
          Datum koriščenja
        </vs-th>
        <vs-th v-if="actionsVisible">
          Akcije
        </vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="'active_' + index" v-for="(tr, index) in data" :data="tr">

          <vs-td :data="data[index]" style="width: 70px;">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              {{ index + 1 }}
            </vs-col>
          </vs-td>

          <vs-td :data="data[index].donor">
            {{data[index].donorData ? data[index].donorData.companyDisplayName : 'brez'}}
          </vs-td>

          <vs-td :data="data[index].data" style="width: 300px;">
            {{data[index].data ? data[index].data.length : 0}}
          </vs-td>

          <vs-td :data="data[index].data" style="width: 200px;">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              <ul>
                <li v-for="(item,idx) in data[index].data" :key="'item_' + idx + '_' + moment(item.date).valueOf()">
                  {{moment(item.date).format('DD. MM. YYYY')}}
                </li>
              </ul>
            </vs-col>
          </vs-td>

          <vs-td :data="data[index]" @mousedown.stop.prevent="stopTheEvent()" style="width: 200px;" v-if="actionsVisible">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              <vs-dropdown>
                <vs-button class="btn-drop" type="filled" icon="expand_more">Akcije</vs-button>

                <vs-dropdown-menu>
                  <vs-dropdown-item @click="printReport(data[index], 'drinkDonor')"> Natisni poročilo
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-col>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <vs-table :data="otherDonationDonorData" class="mt-5" v-if="otherDonationDonorData" striped
              :pagination="false" :max-items="50">
      <template slot="header">
        <div class="d-inline-block float-left">
          <h2>Koriščenje drugih donacij <vs-chip style="float: right;" class="mt-1 ml-3">{{ otherDonationDonorData.length }}</vs-chip></h2>
        </div>
      </template>

      <template slot="thead" v-if="otherDonationDonorData.length > 0">
        <vs-th sort-key="id">
          <!-- empty -->
        </vs-th>
        <vs-th>
          Donator
        </vs-th>
        <vs-th sort-key="first_name">
          Število koriščenih donacij v mesecu
        </vs-th>
        <vs-th sort-key="first_name">
          Datum koriščenja
        </vs-th>
        <vs-th v-if="actionsVisible">
          Akcije
        </vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="'active_' + index" v-for="(tr, index) in data" :data="tr">

          <vs-td :data="data[index]" style="width: 70px;">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              {{ index + 1 }}
            </vs-col>
          </vs-td>

          <vs-td :data="data[index].donor">
            {{data[index].donorData ? data[index].donorData.companyDisplayName : 'brez'}}
          </vs-td>

          <vs-td :data="data[index].data" style="width: 300px;">
            {{data[index].data ? data[index].data.length : 0}}
          </vs-td>

          <vs-td :data="data[index].data" style="width: 200px;">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              <ul>
                <li v-for="(item,idx) in data[index].data" :key="'item_' + idx + '_' + moment(item.date).valueOf()">
                  {{moment(item.date).format('DD. MM. YYYY')}}
                </li>
              </ul>
            </vs-col>
          </vs-td>

          <vs-td :data="data[index]" @mousedown.stop.prevent="stopTheEvent()" style="width: 200px;" v-if="actionsVisible">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              <vs-dropdown>
                <vs-button class="btn-drop" type="filled" icon="expand_more">Akcije</vs-button>

                <vs-dropdown-menu>
                  <vs-dropdown-item @click="printReport(data[index], 'otherDonationDonor')"> Natisni poročilo
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-col>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

  </div>
</template>

<script>
import Vue from "vue";
import sir from '../../../assets/images/portrait/sir.svg';
import madam from '../../../assets/images/portrait/madam.svg';

export default {
  name: 'StatisticsOnDonations',
  components: {},
  props: {
    unitId: Number,
    startDate: Number,
    endDate: Number,
    actionsVisible: Boolean,
  },
  data() {
    return {
      sir: sir,
      madam: madam,
      lunchDonorData: null,
      breakfastDonorData: null,
      drinkDonorData: null,
      otherDonationDonorData: null,
    }
  },
  watch: {
    unitId: {
      handler(val, oldVal) {
        if(val && this.startDate && this.endDate) {
          this.getDailyReports();
        }
      },
      deep: true
    },
    startDate: {
      handler(val, oldVal) {
        if(val && this.endDate) {
          this.getDailyReports();
        }
      },
      deep: true
    },
    endDate: {
      handler(val, oldVal) {
        if(val && this.startDate) {
          this.getDailyReports();
        }
      },
      deep: true
    },
  },
  computed: {},
  mounted() {
    const _this = this;

    _this.getDailyReports();
  },


  methods: {
    getDailyReports() {
      const _this = this;

      _this.$vs.loading();


      const unit = _this.unitId;
      const url = `${Vue.prototype.$config.api.sopotnikiVPS}daily-reports?unit=${unit}&startDate=${_this.moment(_this.startDate).startOf('month').valueOf()}&endDate=${_this.moment(_this.endDate).endOf('month').valueOf()}`;

      Vue.prototype.$http.get(url)
        .then((res) => {
          console.log("_this.dailyReportsData", res.data);
          const dailyReportsData = _.sortBy(res.data.data, ['date']);


          _this.lunchDonorData = _.chain(dailyReportsData)
            .groupBy('lunchDonor.id')
            .map((value, key) => { return {donor: key, donorData: value[0] ? value[0].lunchDonor : {}, data: value}})
            .filter(el => el.donor !== 'undefined')
            .value();
          console.log("_this.lunchDonorData", _this.lunchDonorData);
          //_this.lunchDonorData = _.filter(_this.lunchDonorData, el => el.donor !== 'undefined');

          _this.breakfastDonorData = _.chain(dailyReportsData)
            .groupBy('breakfastDonor.id')
            .map((value, key) => ({donor: key, donorData: value[0] ? value[0].breakfastDonor : {}, data: value}))
            .filter(el => el.donor !== 'undefined')
            .value();

          _this.drinkDonorData = _.chain(dailyReportsData)
            .groupBy('drinkDonor.id')
            .map((value, key) => ({donor: key, donorData: value[0] ? value[0].drinkDonor : {}, data: value}))
            .filter(el => el.donor !== 'undefined')
            .value();

          _this.otherDonationDonorData = _.chain(dailyReportsData)
            .groupBy('otherDonationDonor.id')
            .map((value, key) => ({donor: key, donorData: value[0] ? value[0].otherDonationDonor : {}, data: value}))
            .filter(el => el.donor !== 'undefined')
            .value();


          _this.$vs.loading.close();
        })
        .catch((error) => {
          console.log('error', error);
          _this.$vs.loading.close();
        });
    },

    printReport(data,reportType) {
      // this.$router.push({name: 'StatisticsOnDonations', params: {donorId: data.donorData.id, reportType: reportType, unitId: this.unitId, startDate: this.moment(this.startDate).startOf('month').valueOf(), endDate: this.moment(this.endDate).endOf('month').valueOf()}})

        const _this = this;

        _this.$vs.loading();

        const pdfTemplateUrl = 'https://admin-2-0.sopotniki.org/statistics/statisticsOnDonations?jwt=' + JSON.parse(localStorage.getItem("userInfo")).token + '&donorId=' + data.donorData.id + '&reportType=' + reportType+ '&unitId=' + _this.unitId + '&startDate=' + this.moment(this.startDate).startOf('month').valueOf() + '&endDate=' + this.moment(this.endDate).endOf('month').valueOf();
        console.log("pdfTemplateUrl", pdfTemplateUrl)

        Vue.prototype.$http.get('https://pdf.sopotniki.org/html-to-pdf/' + encodeURIComponent(pdfTemplateUrl),
          {
            headers: {'secret': 'sopotniki-it'}, responseType: 'blob'
          }
        )
          .then((res) => {
            const file = new Blob(
              [res.data],
              {type: 'application/pdf'});

            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(file)
            link.download = `porocilo_o_donacijah_${_this.moment(_this.startDate).startOf('month').format('YYYY_MM')}_${_this.moment(_this.endDate).endOf('month').format('MM')}_${data.donorData.companyDisplayName.replace(/\s/g, '_')}.pdf`
            link.click();
            _this.$vs.loading.close();
          });
    }
  }
}
</script>
