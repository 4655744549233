<template>
  <div>

    <vs-table :data="volunteerData" v-if="volunteerData" striped
              :pagination="false" :max-items="50">
      <template slot="header">
        <div class="d-inline-block float-left mb-2" style="width: 100%">
          <h2 style="display: inline-block">Aktivni prostovoljci
            <vs-chip style="float: right;" class="mt-1 ml-3">{{ volunteerData.length }}</vs-chip>
          </h2>
          <div class="float-right">
            <vs-button color="primary" icon="list"
                       class="float-left"
                       @click.stop="printInsuranceReport">
              Ustvari poročilo za zavarovalnico
            </vs-button>

            <vs-button class="float-left ml-2" color="success" icon="description" @click="exportData"
                       v-if="volunteerData && volunteerData.length > 0">Izvozi v Excel
            </vs-button>
          </div>
        </div>
      </template>

      <template slot="thead" v-if="volunteerData.length > 0">
        <vs-th sort-key="id">
          <!-- empty -->
        </vs-th>
        <vs-th>
          Prostovoljec
        </vs-th>
        <vs-th>
          Število dni aktivnosti
        </vs-th>
        <vs-th>
          Število opravljenih ur prostovoljskega dela
        </vs-th>
        <vs-th>
          Akcije
        </vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="'active_' + index" v-for="(tr, index) in data" :data="tr">

          <vs-td :data="data[index]" style="width: 70px;">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              {{ index + 1 }}
            </vs-col>
          </vs-td>

          <vs-td :data="data[index].donor">
            <h4>
              {{
                data[index].volunteerData ? data[index].volunteerData.firstName + ' ' + data[index].volunteerData.lastName : 'brez'
              }}</h4>
          </vs-td>

          <vs-td :data="data[index].data" style="width: 300px;">
            {{ data[index].data ? data[index].data.length : 0 }}
          </vs-td>

          <vs-td :data="data[index].data" style="width: 300px;">
            {{ calculateTotalHours(data[index].data) }}
          </vs-td>

          <vs-td :data="data[index]" @mousedown.stop.prevent="stopTheEvent()" style="width: 240px;">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">

              <!--                <vs-button size="small" color="primary" class="mx-1" icon="list" :disabled="data[index].volunteerData.travelCostsRefund !== 1 && data[index].volunteerData.travelCostsRefund !== true" @click="printTravelCostsReport(data[index].volunteerData)"> Natisni potne naloge</vs-button>-->
              <vs-button size="small" color="success" class="mx-1" icon="note"
                         @click="printSocialWorkCentreReport(data[index].volunteerData)"> Natisni poročilo za CSD
              </vs-button>
            </vs-col>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <vs-table :data="filteredVolunteerData" v-if="filteredVolunteerData" striped
              :pagination="false" :max-items="50" class="mt-20">
      <template slot="header">
        <div class="d-inline-block float-left mb-2" style="width: 100%">
          <h2 style="display: inline-block">Potni stroški prostovoljcev za obdobje
            {{ moment(startDate).startOf('month').format('DD. MM. YYYY') }} -
            {{ moment(endDate).endOf('month').format('DD. MM. YYYY') }}
            <vs-chip style="float: right;" class="mt-1 ml-3">{{ filteredVolunteerData.length }}</vs-chip>
          </h2>
          <div class="float-right">
            <vs-button class="float-left ml-2" color="success" icon="description" @click="exportTravelCostsData"
                       v-if="volunteerData && volunteerData.length > 0 && unitId !== 24">Izvozi v Excel
            </vs-button>



            <vs-button class="float-left ml-2" color="success" icon="description" @click="exportTravelCostsDataForKrsko"
                       v-if="volunteerData && volunteerData.length > 0 && unitId === 24">Izvozi v Excel za Krško
            </vs-button>
          </div>
        </div>
      </template>

      <template slot="thead" v-if="filteredVolunteerData.length > 0">
        <vs-th sort-key="id">
          <!-- empty -->
        </vs-th>
        <vs-th>
          Prostovoljec
        </vs-th>
        <vs-th>
          Število dni aktivnosti
        </vs-th>
        <vs-th>
          Potni stroški za 1 dan
        </vs-th>
        <vs-th>
          Potni stroški za obdobje
        </vs-th>
        <vs-th>
          Akcije
        </vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="'active_' + index" v-for="(tr, index) in data" :data="tr">

          <vs-td :data="data[index]" style="width: 70px;">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              {{ index + 1 }}
            </vs-col>
          </vs-td>

          <vs-td :data="data[index].volunteerData">
            <h4>
              {{
                data[index].volunteerData ? data[index].volunteerData.firstName + ' ' + data[index].volunteerData.lastName : 'brez'
              }}</h4>
          </vs-td>

          <vs-td :data="data[index].data" style="width: 300px;">
            {{ data[index].data ? data[index].data.length : 0 }}
          </vs-td>

          <vs-td :data="data[index].data" style="width: 300px;">
            {{ parseFloat(data[index].volunteerData.dailyTravelCost).toFixed(2) }} eur
          </vs-td>

          <vs-td :data="data[index].data" style="width: 300px;">
            {{ calculateTotalTravelCosts(data[index].data) }} eur
          </vs-td>

          <vs-td :data="data[index]" @mousedown.stop.prevent="stopTheEvent()" style="width: 240px;">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              <vs-button size="small" color="primary" class="mx-1" icon="list"
                         :disabled="data[index].volunteerData.travelCostsRefund !== 1 && data[index].volunteerData.travelCostsRefund !== true"
                         @click="printTravelCostsReport(data[index].volunteerData)"> Natisni potne naloge
              </vs-button>
            </vs-col>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <vs-table :data="filteredVolunteerData" v-if="filteredVolunteerData" striped
              :pagination="false" :max-items="50" class="mt-20">
      <template slot="header">
        <div class="d-inline-block float-left mb-2" style="width: 100%">
          <h2 style="display: inline-block">DURS poročilo o potnih stroških prostovoljcev za obdobje
            {{ moment(startDate).startOf('month').format('DD. MM. YYYY') }} -
            {{ moment(endDate).endOf('month').format('DD. MM. YYYY') }}
            <vs-chip style="float: right;" class="mt-1 ml-3">{{ filteredVolunteerData.length }}</vs-chip>
          </h2>
          <div class="float-right">
            <vs-button class="float-left ml-2" color="success" icon="description" @click="exportDursTravelCostsData"
                       v-if="filteredVolunteerData && filteredVolunteerData.length > 0">Izvozi v Excel
            </vs-button>
          </div>
        </div>
      </template>

      <template slot="thead" v-if="filteredVolunteerData.length > 0">
        <vs-th>
          Leto
        </vs-th>
        <vs-th>
          DŠ zavezanca za poročilo
        </vs-th>
        <vs-th sort-key="id">
          Zaporedna št.
        </vs-th>
        <vs-th>
          Davčna številka zavezanca
        </vs-th>
        <vs-th>
          Ime
        </vs-th>
        <vs-th>
          Priimek
        </vs-th>
        <vs-th>
          Oznaka vrste dohodka
        </vs-th>
        <vs-th>
          Dohodek, ki se ne všteva oziroma se ne všteva do
          določene višine v davčno osnovo
        </vs-th>
        <vs-th>
          Celoten izplačan dohodek
        </vs-th>
        <vs-th></vs-th>
        <vs-th></vs-th>
        <vs-th>
          Rezidentstvo
        </vs-th>
        <vs-th></vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="'active_' + index" v-for="(tr, index) in data" :data="tr">

          <vs-td :data="data[index].volunteerData" style="width: 300px;">
            {{ moment(startDate).startOf('year').format('YY') }}
          </vs-td>

          <vs-td :data="data[index].volunteerData" style="width: 300px;">
            94930422
          </vs-td>

          <vs-td :data="data[index]" style="width: 70px;">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              {{ index < 9 ? ('0' + (index + 1)) : index + 1 }}
            </vs-col>
          </vs-td>

          <vs-td :data="data[index].data" style="width: 300px;">
            {{ data[index].volunteerData ? data[index].volunteerData.vat : '/' }}
          </vs-td>

          <vs-td :data="data[index].volunteerData" style="width: 300px;">
            {{ data[index].volunteerData ? data[index].volunteerData.firstName : '/' }}
          </vs-td>

          <vs-td :data="data[index].data" style="width: 300px;">
            {{ data[index].volunteerData ? data[index].volunteerData.lastName : '/' }}
          </vs-td>

          <vs-td :data="data[index].data" style="width: 300px;">
            8309
          </vs-td>

          <vs-td :data="data[index].data" style="width: 300px;">
            {{ calculateTotalTravelCosts(data[index].data).padStart(12, '0').replace('.', ',') }}
          </vs-td>

          <vs-td :data="data[index].data" style="width: 300px;">
            {{ calculateTotalTravelCosts(data[index].data).padStart(12, '0').replace('.', '') }}
          </vs-td>
          <vs-td :data="data[index].data" style="width: 300px;"></vs-td>
          <vs-td :data="data[index].data" style="width: 300px;"></vs-td>
          <vs-td :data="data[index].data" style="width: 300px;">R</vs-td>
          <vs-td :data="data[index].data" style="width: 300px;"></vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <div v-if="filteredVolunteerData"><span style="font-family: monospace" v-for="(data,idx) in filteredVolunteerData.filter(item => item.volunteerData.vat)" :key="'pot_' + idx"><pre>{{ moment(startDate).startOf('year').format('YY') }}94930422{{(idx+1).toString().padStart(7, '0')}}{{data.volunteerData.vat}}{{data.volunteerData.firstName.padEnd(20, ' ')}}{{data.volunteerData.lastName.padEnd(20, ' ')}}8309{{ calculateTotalTravelCosts(data.data).padStart(12, ' ').replace('.', '') }}{{' '}}{{ calculateTotalTravelCosts(data.data).padStart(12, ' ').replace('.', '') }}                         R                                 </pre></span></div>

    <vs-table :data="volunteerDataWithInsuranceInfo" v-if="volunteerDataWithInsuranceInfo" striped
              :pagination="false" :max-items="50" class="mt-20">
      <template slot="header">
        <div class="d-inline-block float-left mb-2" style="width: 100%">
          <h2 style="display: inline-block">DURS poročilo o nezgodnem zavarovanju prostovoljcev
            {{ moment(startDate).startOf('month').format('DD. MM. YYYY') }} -
            {{ moment(endDate).endOf('month').format('DD. MM. YYYY') }}
            <vs-chip style="float: right;" class="mt-1 ml-3">{{ volunteerDataWithInsuranceInfo.length }}</vs-chip>
          </h2>
          <div class="float-right">
            <vs-button class="float-left ml-2" color="success" icon="description" @click="exportDursInsuranceCostsData"
                       v-if="volunteerDataWithInsuranceInfo && volunteerDataWithInsuranceInfo.length > 0">Izvozi v Excel
            </vs-button>
          </div>
        </div>
      </template>

      <template slot="thead" v-if="volunteerDataWithInsuranceInfo.length > 0">
        <vs-th>
          Leto
        </vs-th>
        <vs-th>
          DŠ zavezanca za poročilo
        </vs-th>
        <vs-th sort-key="id">
          Zaporedna št.
        </vs-th>
        <vs-th>
          Davčna številka zavezanca
        </vs-th>
        <vs-th>
          Ime
        </vs-th>
        <vs-th>
          Priimek
        </vs-th>
        <vs-th>
          Oznaka vrste dohodka
        </vs-th>
        <vs-th>
          Dohodek, ki se ne všteva oziroma se ne všteva do
          določene višine v davčno osnovo
        </vs-th>
        <vs-th>
          Celoten izplačan dohodek
        </vs-th>
        <vs-th></vs-th>
        <vs-th></vs-th>
        <vs-th>
          Rezidentstvo
        </vs-th>
        <vs-th></vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="'active_' + index" v-for="(tr, index) in data" :data="tr">

          <vs-td :data="data[index].volunteerData" style="width: 300px;">
            {{ moment(startDate).startOf('year').format('YY') }}
          </vs-td>

          <vs-td :data="data[index].volunteerData" style="width: 300px;">
            94930422
          </vs-td>

          <vs-td :data="data[index]" style="width: 70px;">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              {{ index < 9 ? ('0' + (index + 1)) : index + 1 }}
            </vs-col>
          </vs-td>

          <vs-td :data="data[index].data" style="width: 300px;">
            {{ data[index].volunteerData ? data[index].volunteerData.vat : '/' }}
          </vs-td>

          <vs-td :data="data[index].volunteerData" style="width: 300px;">
            {{ data[index].volunteerData ? data[index].volunteerData.firstName : '/' }}
          </vs-td>

          <vs-td :data="data[index].data" style="width: 300px;">
            {{ data[index].volunteerData ? data[index].volunteerData.lastName : '/' }}
          </vs-td>

          <vs-td :data="data[index].data" style="width: 300px;">
            8312
          </vs-td>

          <vs-td :data="data[index]" style="width: 300px;">
            {{ calculateTotalInsuranceCosts(data[index]).padStart(12, ' ').replace('.', ' ') }}
          </vs-td>

          <vs-td :data="data[index]" style="width: 300px;">
            {{ calculateTotalInsuranceCosts(data[index]).padStart(12, ' ').replace('.', ' ') }}
          </vs-td>
          <vs-td :data="data[index].data" style="width: 300px;"></vs-td>
          <vs-td :data="data[index].data" style="width: 300px;"></vs-td>
          <vs-td :data="data[index].data" style="width: 300px;">R</vs-td>
          <vs-td :data="data[index].data" style="width: 300px;"></vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <div v-if="volunteerDataWithInsuranceInfo"><span style="font-family: monospace" v-for="(data,idx) in volunteerDataWithInsuranceInfo.filter(item => item.volunteerData.vat)" :key="'zav_' + idx"><pre>{{ moment(startDate).startOf('year').format('YY') }}94930422{{(idx+1).toString().padStart(7, '0')}}{{data.volunteerData.vat}}{{data.volunteerData.firstName.padEnd(20, ' ')}}{{data.volunteerData.lastName.padEnd(20, ' ')}}8312{{ calculateTotalInsuranceCosts(data).padStart(12, ' ').replace('.', '') }}{{' '}}{{ calculateTotalInsuranceCosts(data).padStart(12, ' ').replace('.', '') }}                         R                                 </pre></span></div>

  </div>
</template>

<script>
import Vue from "vue";
import sir from '../../../assets/images/portrait/sir.svg';
import madam from '../../../assets/images/portrait/madam.svg';
import xlsx from "json-as-xlsx";

export default {
  name: 'StatisticsOnDonations',
  components: {},
  props: {
    unitId: Number,
    startDate: Number,
    endDate: Number
  },
  data() {
    return {
      sir: sir,
      madam: madam,
      volunteerData: null,
      breakfastDonorData: null,
      drinkDonorData: null,
      otherDonationDonorData: null,
      volunteerDataWithInsuranceInfo: [],
    }
  },
  watch: {
    unitId: {
      handler(val, oldVal) {
        if (val && this.startDate && this.endDate) {
          this.getDailyReports();
        }
      },
      deep: true
    },
    startDate: {
      handler(val, oldVal) {
        if (val && this.endDate) {
          this.getDailyReports();
        }
      },
      deep: true
    },
    endDate: {
      handler(val, oldVal) {
        if (val && this.startDate) {
          this.getDailyReports();
        }
      },
      deep: true
    },
  },
  computed: {
    filteredVolunteerData() {
      if(!this.volunteerData) {
        return [];
      }
      const filteredArray = this.volunteerData.filter(
        (item) => item.volunteerData.travelCostsRefund === 1
      );

      return filteredArray;
    }
  },
  mounted() {
    const _this = this;

    _this.getDailyReports();
  },


  methods: {
    getDailyReports() {
      const _this = this;

      _this.$vs.loading();


      const unit = _this.unitId;
      const url = `${Vue.prototype.$config.api.sopotnikiVPS}daily-reports?unit=${unit}&startDate=${_this.moment(_this.startDate).startOf('month').valueOf()}&endDate=${_this.moment(_this.endDate).endOf('month').valueOf()}`;

      Vue.prototype.$http.get(url)
        .then(async (res) => {
          console.log("_this.dailyReportsData", res.data);
          const dailyReportsData = _.sortBy(res.data.data, ['date']);


          let volunteerData = _.chain(dailyReportsData)
            .groupBy('volunteer.id')
            .map((value, key) => {
              return {volunteer: key, volunteerData: value[0].volunteer, data: value}
            })
            .filter(el => el.volunteerData.firstName !== 'Ni' && el.volunteerData.lastName !== 'Voženj')
            .value();

          _this.volunteerData = _.sortBy(volunteerData, [function (o) {
            return o.data.length;
          }]).reverse();

          //volunteerDataWithInsuranceInfo
          await _this.$globalFunctions.sleep(3500);

          _.each(volunteerData, (_item) => {
            console.log("item", _item);
            const uniqueMonths = new Set();

            _item.data.forEach(item => {
              const date = new Date(item.date);
              const yearMonth = `${date.getFullYear()}-${date.getMonth()}`;
              uniqueMonths.add(yearMonth);
            });

            const numberOfDistinctMonths = uniqueMonths.size;
            console.log(numberOfDistinctMonths);
            _item.insuranceMonths = numberOfDistinctMonths;
            console.log("_item", _item);
            if (_item) {
              _this.volunteerDataWithInsuranceInfo.push(_item);
            }
          });
          console.log("volunteerDataWithInsuranceInfo", _this.volunteerDataWithInsuranceInfo);

          _this.$vs.loading.close();
        })
        .catch((error) => {
          console.log('error', error);
          _this.$vs.loading.close();
        });
    },

    calculateTotalHours(data) {
      console.log("data ---> ", data);
      let totalMinutes = null;
      _.each(data, function (val) {
        totalMinutes += val.volunteerHoursTotal ? (Number(val.volunteerHoursTotal.split(':')[0]) * 60) : 0;
        totalMinutes += val.volunteerHoursTotal ? Number(val.volunteerHoursTotal.split(':')[1]) : 0;
      });
      console.log("totalMinutes", totalMinutes);
      return this.convertMinsToHrsMins(totalMinutes);
    },

    convertMinsToHrsMins(minutes) {
      let h = Math.floor(minutes / 60);
      let m = minutes % 60;
      h = h < 10 ? '0' + h : h;
      m = m < 10 ? '0' + m : m;
      return h + ':' + m;
    },

    calculateTotalTravelCosts(data) {
      console.log("data ---> ", data);
      let totalTravelCosts = null;
      _.each(data, function (val) {
        totalTravelCosts += val.volunteer.travelCostsRefund ? Number(val.volunteer.dailyTravelCost) : 0;
      });
      console.log("totalTravelCosts", totalTravelCosts);
      return parseFloat(totalTravelCosts).toFixed(2);
    },

    calculateTotalInsuranceCosts(data) {
      console.log("calculateTotalInsuranceCosts ---> ", data);
      const totalInsuranceCosts = data.insuranceMonths * 0.71;
      return parseFloat(totalInsuranceCosts).toFixed(2);
    },

    printInsuranceReport() {
      //this.$router.push({name: 'StatisticsOnVolunteersInsurance', params: {unitId: this.unitId, startDate: this.moment(this.startDate).startOf('month').valueOf(), endDate: this.moment(this.endDate).endOf('month').valueOf()}})

      const _this = this;

      _this.$vs.loading();

      const pdfTemplateUrl = 'https://admin-2-0.sopotniki.org/statistics/statisticsOnVolunteers/insurance?jwt=' + JSON.parse(localStorage.getItem("userInfo")).token + '&unitId=' + _this.unitId + '&startDate=' + _this.moment(_this.startDate).startOf('month').valueOf() + '&endDate=' + _this.moment(_this.endDate).endOf('month').valueOf();
      console.log("pdfTemplateUrl", pdfTemplateUrl)

      Vue.prototype.$http.get('https://pdf.sopotniki.org/html-to-pdf/' + encodeURIComponent(pdfTemplateUrl),
        {
          headers: {'secret': 'sopotniki-it'}, responseType: 'blob'
        }
      )
        .then((res) => {
          const file = new Blob(
            [res.data],
            {type: 'application/pdf'});

          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(file)
          link.download = `porocilo_za_zavarovanje_${_this.moment(_this.startDate).startOf('month').format('YYYY_MM')}.pdf`
          link.click();
          _this.$vs.loading.close();
        });
    },

    printSocialWorkCentreReport(volunteer) {
      //this.$router.push({name: 'StatisticsOnVolunteersSocialWorkCentre', params: {volunteerId: volunteer.id, unitId: this.unitId, startDate: this.moment(this.startDate).startOf('month').valueOf(), endDate: this.moment(this.endDate).endOf('month').valueOf()}})

      const _this = this;

      _this.$vs.loading();

      const pdfTemplateUrl = 'https://admin-2-0.sopotniki.org/statistics/statisticsOnVolunteers/socialWorkCentre?jwt=' + JSON.parse(localStorage.getItem("userInfo")).token + '&volunteerId=' + volunteer.id + '&unitId=' + _this.unitId + '&startDate=' + _this.moment(_this.startDate).startOf('month').valueOf() + '&endDate=' + _this.moment(_this.endDate).endOf('month').valueOf();
      console.log("pdfTemplateUrl", pdfTemplateUrl)

      Vue.prototype.$http.get('https://pdf.sopotniki.org/html-to-pdf/' + encodeURIComponent(pdfTemplateUrl),
        {
          headers: {'secret': 'sopotniki-it'}, responseType: 'blob'
        }
      )
        .then((res) => {
          const file = new Blob(
            [res.data],
            {type: 'application/pdf'});

          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(file)
          link.download = `porocilo_za_csd_${_this.moment(_this.startDate).startOf('month').format('YYYY_MM')}_${volunteer.firstName}_${volunteer.lastName}.pdf`
          link.click();
          _this.$vs.loading.close();
        });
    },

    printTravelCostsReport(volunteer) {
      //this.$router.push({name: 'StatisticsOnVolunteersTravelCosts', params: {volunteerId: volunteer.id, unitId: this.unitId, startDate: this.moment(this.startDate).startOf('month').valueOf(), endDate: this.moment(this.endDate).endOf('month').valueOf()}})

      const _this = this;

      _this.$vs.loading();

      const pdfTemplateUrl = 'https://admin-2-0.sopotniki.org/statistics/statisticsOnVolunteers/travelCosts?jwt=' + JSON.parse(localStorage.getItem("userInfo")).token + '&volunteerId=' + volunteer.id + '&unitId=' + _this.unitId + '&startDate=' + _this.moment(_this.startDate).startOf('month').valueOf() + '&endDate=' + _this.moment(_this.endDate).endOf('month').valueOf();
      console.log("pdfTemplateUrl", pdfTemplateUrl)

      Vue.prototype.$http.get('https://pdf.sopotniki.org/html-to-pdf/' + encodeURIComponent(pdfTemplateUrl),
        {
          headers: {'secret': 'sopotniki-it'}, responseType: 'blob'
        }
      )
        .then((res) => {
          const file = new Blob(
            [res.data],
            {type: 'application/pdf'});

          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(file)
          link.download = `potni_stroski_${_this.moment(_this.startDate).startOf('month').format('YYYY_MM')}_${_this.moment(_this.endDate).endOf('month').format('MM')}_${volunteer.firstName}_${volunteer.lastName}.pdf`
          link.click();
          _this.$vs.loading.close();
        });
    },

    exportData() {
      const _this = this;
      const tableData = _.cloneDeep(this.volunteerData).map((obj, idx) => ({...obj, idx: (idx + 1)}));

      let data = [
        {
          sheet: 'Poročilo o prostovoljskih urah',
          columns: [
            {
              label: '#',
              value: (row) => (row ? row.idx || '' : ''),
            }, // Run functions
            {
              label: 'Prostovoljec',
              value: (row) => (row.volunteerData ? row.volunteerData.firstName + ' ' + row.volunteerData.lastName || '' : ''),
            }, // Run functions
            {
              label: 'Datum rojstva',
              value: (row) => (row.volunteerData ? _this.moment(row.volunteerData.birthday).format('DD. MM. YYYY') || '' : '')
            }, // Run functions
            {label: 'Naslov', value: (row) => (row.volunteerData ? row.volunteerData.address.quickSearch : '')}, // Run functions
            {label: 'Število dni', value: (row) => (row.data ? row.data.length : '')}, // Run functions
            {label: 'Število ur', value: (row) => (row.data ? _this.calculateTotalHours(row.data) || '' : '')}, // Run functions
          ],
          content: tableData,
        },
      ];
      let settings = {
        fileName: `prostovoljske_ure_${_this.moment(_this.startDate).startOf('month').format('YYYY_MM')}_${_this.moment(_this.endDate).endOf('month').format('MM')}`,
      };
      xlsx(data, settings);

    },

    exportTravelCostsData() {
      const _this = this;
      const tableData = _.cloneDeep(this.filteredVolunteerData).map((obj, idx) => ({...obj, idx: (idx + 1)}));

      let data = [
        {
          sheet: 'Povračilo potnih stroškov',
          columns: [
            {
              label: '#',
              value: (row) => (row ? row.idx || '' : ''),
            }, // Run functions
            {
              label: 'Prostovoljec',
              value: (row) => (row.volunteerData ? row.volunteerData.firstName + ' ' + row.volunteerData.lastName || '' : ''),
            }, // Run functions
            {label: 'Število dni aktivnosti', value: (row) => (row.data ? row.data.length : '')}, // Run functions
            {
              label: 'Potni stroški za 1 dan',
              value: (row) => (row.volunteerData ? Number(parseFloat(row.volunteerData.dailyTravelCost).toFixed(2)).toLocaleString('sl', {
                minimumFractionDigits: 2
              }) || '' : '')
            }, // Run functions
            {
              label: `Potni stroški za obdobje ${_this.moment(_this.startDate).startOf('month').format('DD. MM. YYYY')} - ${_this.moment(_this.endDate).endOf('month').format('DD. MM. YYYY')}`,
              value: (row) => (row.data ? Number(_this.calculateTotalTravelCosts(row.data)).toLocaleString('sl', {
                minimumFractionDigits: 2
              }) || '' : '')
            }, // Run functions
          ],
          content: tableData,
        },
      ];
      let settings = {
        fileName: `potni_stroski_${_this.moment(_this.startDate).startOf('month').format('YYYY_MM')}_${_this.moment(_this.endDate).endOf('month').format('MM')}`,
      };
      xlsx(data, settings);
    },

    exportTravelCostsDataForKrsko() {
      const _this = this;
      const tableData = _.cloneDeep(this.filteredVolunteerData).map((obj, idx) => ({...obj, idx: (idx + 1)}));
      console.log("tableData", tableData);

      let data = [
        {
          sheet: `Obdobje`,
          columns: [
            {
              label: '#',
              value: (row) => (row ? row.idx || '' : ''),
            }, // Run functions
            {
              label: 'Prostovoljec',
              value: (row) => (row.volunteerData ? row.volunteerData.firstName + ' ' + row.volunteerData.lastName || '' : ''),
            }, // Run functions
            {
              label: 'Naslov',
              value: (row) => (row.volunteerData ? row.volunteerData.address.quickSearch || '' : ''),
            }, // Run functions
            {
              label: 'km v dve smeri',
              value: (row) => (row.volunteerData ? Number(parseFloat(row.volunteerData.dailyTravelDistance).toFixed(2)).toLocaleString('sl', {
                minimumFractionDigits: 3
              }) || '' : ''),
            }, // Run functions
            {
              label: 'izračun km',
              value: (row) => (row.volunteerData ? Number(parseFloat(row.volunteerData.dailyTravelCost).toFixed(2)).toLocaleString('sl', {
                minimumFractionDigits: 3
              }) || '' : '')
            }, // Run functions
            {
              label: 'število dni',
              value: (row) => (row.data ? row.data.length : ''),
            }, // Run functions
            {
              label: 'izračun potnega stroška',
              value: (row) => (row.data ? Number(_this.calculateTotalTravelCosts(row.data)).toLocaleString('sl', {
                minimumFractionDigits: 3
              }) || '' : ''),
            }, // Run functions
            {
              label: 'število prostovoljskih ur',
              value: (row) => (row.data ? _this.calculateTotalHours(row.data) || '' : '')
            }, // Run functions
          ],
          content: tableData,
        },
      ];
      let settings = {
        fileName: `Sopotniki_potni stroski_Krsko_${_this.moment(_this.startDate).startOf('month').format('YYYY_MM')}_${_this.moment(_this.endDate).endOf('month').format('MM')}2023_za_CSD`,
      };
      xlsx(data, settings);
    },

    exportDursTravelCostsData() {
      const _this = this;
      const tableData = _.cloneDeep(this.filteredVolunteerData).map((obj, idx) => ({...obj, idx: (idx + 1)}));

      let data = [
        {
          sheet: 'DURS - potni stroški',
          columns: [
            {
              label: 'leto',
              value: (row) => (row ? _this.moment(_this.startDate).startOf('month').format('YY') || '' : ''),
            },
            {
              label: 'DŠ zavezanca za poročilo',
              value: (row) => (row ? '94930422' : ''),
            },
            {
              label: '#',
              value: (row) => (row ? row.idx < 9 ? ('0' + (row.idx + 1)) : (row.idx + 1) || '' : ''),
            }, // Run functions
            {
              label: 'DŠ zavezanca',
              value: (row) => (row.volunteerData ? row.volunteerData.vat || '' : ''),
            },
            {
              label: 'Ime',
              value: (row) => (row.volunteerData ? row.volunteerData.firstName || '' : ''),
            },
            {
              label: 'Priimek',
              value: (row) => (row.volunteerData ? row.volunteerData.lastName || '' : ''),
            },
            {
              label: 'Oznaka vrste dohodka',
              value: (row) => (row ? '8309' : ''),
            },
            {
              label: `Dohodek, ki se ne všteva oziroma se ne všteva do določene višine v davčno osnovo`,
              value: (row) => (row.data ? _this.calculateTotalTravelCosts(row.data).padStart(11, '0').replace('.', ',') || '' : '')
            }, // Run functions
            {
              label: `Celoten izplačan dohodek`,
              value: (row) => (row.data ? _this.calculateTotalTravelCosts(row.data).padStart(11, '0').replace('.', ',') || '' : '')
            }, // Run functions
            {
              label: 'P1',
              value: '\'',
            },
            {
              label: 'P2',
              value: '\'',
            },
            {
              label: 'Rezidentstvo',
              value: (row) => (row ? 'R' : ''),
            },
            {
              label: 'P3',
              value: '\'',
            },
          ],
          content: tableData,
        },
      ];
      let settings = {
        fileName: `DURS_potni_stroski_${_this.moment(_this.startDate).startOf('year').format('YY')}`,
      };
      xlsx(data, settings);
    },

    exportDursInsuranceCostsData() {
      const _this = this;
      const tableData = _.cloneDeep(this.volunteerDataWithInsuranceInfo).map((obj, idx) => ({...obj, idx: (idx + 1)}));

      let data = [
        {
          sheet: 'DURS - nezgodno zavarovane',
          columns: [
            {
              label: 'leto',
              value: (row) => (row ? _this.moment(_this.startDate).startOf('month').format('YY') || '' : ''),
            },
            {
              label: 'DŠ zavezanca za poročilo',
              value: (row) => (row ? '94930422' : ''),
            },
            {
              label: '#',
              value: (row) => (row ? row.idx < 9 ? ('0' + (row.idx + 1)) : (row.idx + 1) || '' : ''),
            }, // Run functions
            {
              label: 'DŠ zavezanca',
              value: (row) => (row.volunteerData ? row.volunteerData.vat || '' : ''),
            },
            {
              label: 'Ime',
              value: (row) => (row.volunteerData ? row.volunteerData.firstName || '' : ''),
            },
            {
              label: 'Priimek',
              value: (row) => (row.volunteerData ? row.volunteerData.lastName || '' : ''),
            },
            {
              label: 'Oznaka vrste dohodka',
              value: (row) => (row ? '8309' : ''),
            },
            {
              label: `Dohodek, ki se ne všteva oziroma se ne všteva do določene višine v davčno osnovo`,
              value: (row) => (row.data ? _this.calculateTotalInsuranceCosts(row).padStart(11, '0').replace('.', ',') || '' : '')
            }, // Run functions
            {
              label: `Celoten izplačan dohodek`,
              value: (row) => (row.data ? _this.calculateTotalInsuranceCosts(row).padStart(11, '0').replace('.', ',') || '' : '')
            }, // Run functions
            {
              label: 'P1',
              value: '\'',
            },
            {
              label: 'P2',
              value: '\'',
            },
            {
              label: 'Rezidentstvo',
              value: (row) => (row ? 'R' : ''),
            },
            {
              label: 'P3',
              value: '\'',
            },
          ],
          content: tableData,
        },
      ];
      let settings = {
        fileName: `DURS_zavarovanje_${_this.moment(_this.startDate).startOf('year').format('YY')}`,
      };
      xlsx(data, settings);
    }
  }
}
</script>
