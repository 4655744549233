<template>
  <div>
    <div id="statistics">
      <vs-breadcrumb class="mb-5">
        <li>
          <router-link to="/">Domov</router-link>
          <span class="vs-breadcrum--separator">/</span></li>
        <li aria-current="page" class="active">Statistika</li>
      </vs-breadcrumb>

      <vs-row>
        <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="12">
<!--          <vs-button color="primary" size="small" class="mt-1 mr-2" icon="keyboard_arrow_left"-->
<!--                     @click="() => {startDate = moment(startDate).subtract(1, 'M').endDate(); getFinancialReports()}"></vs-button>-->
          <datepicker :minimumView="'month'" :maximumView="'year'" :language="slSI" :format="customDateFormatter"
                      :monday-first="true"
                      v-model="startDate"></datepicker>

          &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;

          <datepicker :minimumView="'month'" :maximumView="'year'" :language="slSI" :format="customDateFormatter"
                      :monday-first="true"
                      v-model="endDate"></datepicker>

<!--          <vs-button color="primary" size="small " class="mt-1 ml-2" icon="keyboard_arrow_right"-->
<!--                     @click="() => {startDate = moment(startDate).add(1, 'M').endDate(); getFinancialReports()}"></vs-button>-->

          <v-select id="unit" :options="unitsData" class="ml-20" @input="onUnitChange" style="min-width: 300px"
                    v-model="unitId" placeholder="Filtriraj zadetke po enoti"
                    :reduce="item => item.id"
                    v-if="$globalFunctions.isAdmin() && unitsData"
                    :clearable="false"
                    label="name">

            <template v-slot:option="option">
              <vs-list-item
                :title="option.name"
                :subtitle="option.coveredMunicipalities">
                <template slot="avatar" v-if="option.name">
                  <vs-avatar size="large"
                             :text="option && option.name && option.name.toString().substr(0,1) + (option.name.toString().match(/\d/g) ? option.name.toString().match(/\d/g).join('') : '')"
                             class="mr-5"/>
                </template>
              </vs-list-item>
            </template>

            <template v-slot:selected-option="option">
              <vs-list-item
                :title="option.name"
                :subtitle="option.coveredMunicipalities">
                <template slot="avatar" v-if="option.name">
                  <vs-avatar size="large"
                             :text="option && option.name && option.name.toString().substr(0,1) + (option.name.toString().match(/\d/g) ? option.name.toString().match(/\d/g).join('') : '')"
                             class="mr-5"/>
                </template>
              </vs-list-item>
            </template>

            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
              </template>
              <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
            </template>
          </v-select>

        </vs-col>
      </vs-row>


      <vs-tabs>
        <vs-tab label="Koriščenje donacij" icon="check_circle" color="success" @click="">
          <StatisticsOnDonations :unit-id="unitId" :start-date="moment(startDate).valueOf()" :end-date="moment(endDate).valueOf()" class="mt-5" :actionsVisible="true"></StatisticsOnDonations>
        </vs-tab>
        <vs-tab label="Prostovoljci" icon="volunteer_activism" color="success" @click="">
          <StatisticsOnVolunteers :unit-id="unitId" :start-date="moment(startDate).valueOf()" :end-date="moment(endDate).valueOf()" class="mt-5"></StatisticsOnVolunteers>
        </vs-tab>
      </vs-tabs>


<!--      <vs-row class="mt-5">-->
<!--        <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="12">-->
<!--          <iframe-->
<!--            ref="oldAdminIframe"-->
<!--            src="https://admin.sopotniki.org/#/app/statistika"-->
<!--            frameborder="0"-->
<!--            width="800"-->
<!--            height="1200"-->
<!--            style="width: 100%;"-->
<!--          ></iframe>-->
<!--        </vs-col>-->
<!--      </vs-row>-->

<!--      <vs-row class="mt-5">-->
<!--        <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="6">-->
<!--          <iframe-->
<!--            :src="renderVolunteersChart"-->
<!--            frameborder="0"-->
<!--            width="800"-->
<!--            height="600"-->
<!--          ></iframe>-->
<!--        </vs-col>-->
<!--        <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="6">-->
<!--          <iframe-->
<!--            :src="renderReservationsChart"-->
<!--            frameborder="0"-->
<!--            width="800"-->
<!--            height="600"-->
<!--          ></iframe>-->
<!--        </vs-col>-->
<!--      </vs-row>-->


    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import jwt from 'jsonwebtoken';
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import {slSI} from "vuejs-datepicker/dist/locale";
import StatisticsOnDonations from "@/components/Sopotniki/statistics/StatisticsOnDonations";
import StatisticsOnVolunteers from "@/components/Sopotniki/statistics/StatisticsOnVolunteers";

let METABASE_SITE_URL = "https://statistics2.azurewebsites.net";
let METABASE_SECRET_KEY = "c445daad75cf351e373ffcf0527ccc67c3b5748eee47a35bb1f2fe58e59183b0";

export default {
  name: 'Statistics',

  components: {
    'v-select': vSelect,
    Datepicker,
    StatisticsOnDonations,
    StatisticsOnVolunteers
  },

  data() {
    return {
      startDate: new Date(),
      endDate: new Date(),
      slSI: slSI,
      unitsData: null,
      unitId: null,
    }
  },

  computed: {
    renderVolunteersChart() {
      const _this = this;

      console.log("this.$globalFunctions.getCurrentUnit().id", _this.unitId);
      let payload = {
        resource: {question: 32},
        params: {
          unitId: _this.unitId
        },
        exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minute expiration
      };
      let token = jwt.sign(payload, METABASE_SECRET_KEY);

      return METABASE_SITE_URL + "/embed/question/" + token + "#bordered=true&titled=true";
    },

    renderReservationsChart() {
      const _this = this;

      console.log("this.$globalFunctions.getCurrentUnit().id", _this.unitId);
      let payload = {
        resource: {question: 34},
        params: {
          unitId: _this.unitId
        },
        exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minute expiration
      };
      let token = jwt.sign(payload, METABASE_SECRET_KEY);

      return METABASE_SITE_URL + "/embed/question/" + token + "#bordered=true&titled=true";
    }
  },

  mounted() {
    const _this = this;

    _this.unitId = _this.$globalFunctions.getCurrentUnit().id;

    _this.$vs.loading();
    let p1 = this.getUnits();

    Promise.all([p1]).then(() => {
      _this.$vs.loading.close();
    });
  },

  methods: {
    async getUnits() {
      const _this = this;

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionNew + 'units')
        .then((res) => {

          _this.unitsData = _.filter(res.data.data, {disabled: 0});
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });

    },

    onUnitChange() {
      // this.getFinancialReports();
    },

    customDateFormatter(date) {
      return this.moment(date).format('MMMM, YYYY');
    },

  },
}
</script>

